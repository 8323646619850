export const asideMenu = [
  {
    name: 'Профиль',
    isOpen: true,
    id: 1,
    childs: [
      {
        url: '/cabinet/my-companies/',
        name: 'Мои компании',
        id: 11,
      },
      {
        url: '/cabinet/dannue-polzovatelya/',
        name: 'Данные пользователя',
        id: 12,
      },
    ],
  },
  {
    name: 'Мои заказы',
    isOpen: true,
    id: 2,
    childs: [
      {
        url: '/cabinet/order-history/',
        name: 'История заказов',
        id: 21,
      },
      {
        url: '/cabinet/delayed-products/',
        name: 'Отложенные товары',
        id: 22,
      },
      // {
      //   url: null,
      //   name: "Конфигурации",
      //   id: 23,
      // },
    ],
  },
  // {
  //   name: "Бонусы",
  //   isOpen: true,
  //   id: 3,
  //   childs: [
  //     {
  //       url: null,
  //       name: "Условия бонусной программы",
  //       id: 31,
  //     },
  //     {
  //       url: null,
  //       name: "История бонусных операций",
  //       id: 32,
  //     },
  //     {
  //       url: null,
  //       name: "Обменять бонусы",
  //       id: 33,
  //     },
  //     {
  //       url: null,
  //       name: "Участие в благотворительности",
  //       id: 34,
  //     },
  //   ],
  // },
  {
    name: 'Сервисы',
    isOpen: true,
    id: 4,
    childs: [
      // {
      //   url: null,
      //   name: "Управление пописками",
      //   id: 41,
      // },
      // {
      //   url: null,
      //   name: "Материалы для скачивания",
      //   id: 42,
      // },
      {
        url: '/cabinet/my-tools/',
        name: 'Мои инструменты',
        id: 43,
      },
    ],
  },
];

export const users = [
  {
    id: 1,
    name: 'Иван',
    surname: 'Иванович',
    middleName: 'Иванов',
    position: 'Менеджер по закупкам',
    phone: {
      main: '79050500551',
      mobile: '79050500551',
      other: '79050500551',
    },
    mail: 'mail@mail.ru',
    date: '12.05.1982',
    sex: 'male',
    isMain: true,
  },
  {
    id: 2,
    name: 'Дмитрий',
    surname: 'Иванович',
    middleName: 'Сергеевич',
    position: 'Генеральный директор',
    phone: {
      main: '74992550551',
      mobile: '79050500551',
    },
    mail: 'mail@mail.ru',
    date: '12.05.1974',
    sex: 'male',
    isMain: false,
  },
];
export const helpText =
  'Добавление и изменение контактных данных пользоватлей возможно ТОЛЬКО через запрос менеджеру. <br> Срок внесения изменений от 2 дней';

export const companies = [
  {
    fullName: 'Технологии и Машины',
    shortName: 'ТМ',
    legalAddress: 'ул.Красный проспект 78',
    localAddress: 'ул.Красный проспект 44',
    phone: '79235412785',
    mail: 'tm@mail.ru',
    site: 'www.dns-shop.com',
    inn: '1232312312',
    ogrn: '79135248591',
    kpp: '343434343',
    bankName: 'Сбербанк',
    bic: '565656565',
    calcBill: '12312312312312312000',
    correspodentBill: '12312312234312313000',
    headName: 'Олег',
    headPosition: 'Директор',
    base: '79835428156',
    isMain: true,
  },
  {
    fullName: 'Технологии и Машины и Машины',
    shortName: 'ТММ',
    legalAddress: 'ул.Красный проспект 72',
    localAddress: 'ул.Красный проспект 42',
    phone: '79235412782',
    mail: 'tmь@mail.ru',
    site: 'www.dns-shop.com',
    inn: '1232312311',
    ogrn: '79135248590',
    kpp: '343434342',
    bankName: 'Тинькофф',
    bic: '565656562',
    calcBill: '12312312312312312001',
    correspodentBill: '12312312234312313001',
    headName: 'Валерий',
    headPosition: 'Директор',
    base: '79835428152',
    isMain: false,
  },
];
